<template>
  <van-nav-bar
    :title="title"
  />
  <form action="/">
    <van-search v-model="searchKey" placeholder="搜索" @clear="onClear" @search="onSearch" />
  </form>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="(item, index) in items" :key="index">
      <div style="margin:7px 0 0 0;padding:15px;background:#fff;line-height:1.6;">
        <p>
          <small>会员姓名：{{ item.name }}</small>
        </p>
        <p v-if="item.phone !== ''">
          <small>手机号：{{ item.user.phone }}</small>
        </p>
        <van-button type="primary" size="small" @click="getDetail(item.memberId, index)">查看资料</van-button>
        <van-button style="margin-left: 6px;" type="primary" size="small" @click="openRecord(item.memberId)">交易记录</van-button>
      </div>
    </template>
  </van-list>
  <van-overlay :show="detailShow" :z-index="2002" :lock-scroll="false">
    <div class="formbox">
      <van-form @submit="onSubmit">
        <van-cell-group title="基本信息" inset>
          <van-field
            disabled
            v-model="memberForm.phone"
            name="phone"
            label="会员电话"
            placeholder="请输入"
          />
          <van-field
            v-model="memberForm.name"
            name="name"
            label="会员姓名"
            placeholder="请输入"
          />
          <van-field name="radio" label="会员性别">
            <template #input>
              <van-radio-group v-model="memberForm.gender" direction="horizontal">
                <van-radio name="SECRET">秘密</van-radio>
                <van-radio name="MALE">帅哥</van-radio>
                <van-radio name="FEMALE">美女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="memberForm.birthday"
            readonly
            label="会员生日"
            name="datetimePicker"
            placeholder="点击选择"
            @click="showBirth = true"
          />
          <van-popup v-model:show="showBirth" position="bottom">
            <van-datetime-picker
              type="date"
              @confirm="onBirthConfirm"
              @cancel="showBirth = false"
              :min-date="new Date('1950/01/01')"
              :max-date="new Date()"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="areaCode"
            :model-value="memberForm.region"
            label="所在区域"
            placeholder="点击选择"
            @click="showArea = true"
          />
          <van-popup v-model:show="showArea" position="bottom">
            <van-area
              :columns-num="3"
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <van-field
            v-model="memberForm.address"
            name="address"
            label="详细地址"
            placeholder="请输入"
          />
        </van-cell-group>
        <van-cell-group title="会员标签" inset>
          <van-field name="tagbtn" label="">
            <template #input>
               <van-button size="small" type="primary" @click="openTag">添加标签</van-button>
            </template>
          </van-field>
          <van-dialog v-model:show="showTag" title="添加标签" show-cancel-button @confirm="tagConfirm">
            <van-field
              v-model="tagText"
              name="tagText"
              label="标签名称"
              placeholder="请输入标签,最多10个字"
              :maxlength="10"
            />
          </van-dialog>
          <van-field name="tag" label="">
            <template #input>
              <div>
                <div v-if="memberForm.label.length === 0" style="color: #999999;font-size: 12px;">尚未添加标签</div>
                <van-tag size="large" style="margin-right: 6px;margin-bottom: 6px;" closeable @close="delTag(index)" type="primary" v-for="(item,index) in memberForm.label" :key="index">{{ item }}</van-tag>
              </div>
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group title="会员备注" inset>
          <van-field
            v-model="memberForm.remarks"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入"
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button block style="margin-bottom: 8px;" type="primary" @click="detailShow = false">取消</van-button>
          <van-button block type="primary" native-type="submit" :loading="btnLoading">确认修改</van-button>
        </div>
      </van-form>
    </div>
  </van-overlay>
  <van-action-sheet v-model:show="memberShow">
    <van-tabs v-model:active="tab" @change="changeTab">
      <van-tab title="普通商品" name="GENERAL"></van-tab>
      <van-tab title="自由付" name="FREE_PAY"></van-tab>
    </van-tabs>
    <van-list
      style="height: 60vh;padding: 8px;"
      v-model:loading="memberLoading"
      :finished="memberFinished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="getMemberList"
    >
      <template v-for="(item,index) in memberList" :key="index">
        <div class="infobox">
          <p>
            <small>订单编号： {{ item.orderNumber }}</small>
          </p>
          <p>
            <small>下单时间： {{ item.createTime }}</small>
          </p>
          <van-card
            :num="item.productQuantity"
            :price="item.productPrice"
            :desc="item.productSkuText"
            :title="item.productName"
            :thumb="item.productImage"
          >
            <template #tags v-if="item.bookingDate">
              <van-tag plain type="danger">{{ item.bookingDate }}</van-tag>
            </template>
          </van-card>
        </div>
      </template>
    </van-list>
  </van-action-sheet>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'
import { Toast } from 'vant'
import Areas from '@/util/area'
// Dialog
export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const Cookies = inject('Cookies')
    const state = reactive({
      searchKey: '',
      page: 1,
      finished: false,
      loading: false,
      items: [],
      userId: Cookies.get('userId'),
      storeId: Cookies.get('storeId'),
      staffId: Cookies.get('staffId'),
      title: '',
      memberIndex: '',
      detailShow: false,
      memberForm: {
        memberId: '',
        phone: '',
        name: '',
        gender: '',
        birthday: '',
        remarks: '',
        address: '',
        region: '',
        label: []
      },
      showArea: false,
      areaList: Areas,
      showBirth: false,
      showTag: false,
      tagText: '',
      btnLoading: false,
      memberShow: false,
      memberLoading: false,
      memberFinished: false,
      memberId: '',
      memberList: [],
      memberPage: 1,
      tab: 'GENERAL'
    })
    const changeTab = (e) => {
      state.memberList = []
      state.memberPage = 1
      getMemberList()
    }
    const openRecord = (id) => {
      state.memberId = id
      state.memberList = []
      state.memberPage = 1
      getMemberList()
    }
    const getMemberList = () => {
      const data = {
        memberId: state.memberId,
        storeId: state.storeId,
        pageNum: state.memberPage,
        bizScope: state.tab,
        status: 'COMPLETED'
      }
      post('/order.list', data).then(res => {
        if (res.code === 0) {
          if (!state.memberShow) state.memberShow = true
          if (data.pageNum === 1) {
            state.memberList = res.data.content
          } else {
            state.memberList = [...state.memberList, ...res.data.content]
          }
          state.memberLoading = false
          state.memberFinished = res.data.last
          state.memberPage++
        } else {
          Toast(res.msg)
        }
      })
    }
    const getDetail = (memberId, index) => {
      state.memberIndex = index
      post('/member.get', {
        memberId: memberId
      }).then(res => {
        console.log(res)
        if (res.code === 0) {
          state.memberForm = {
            memberId: memberId,
            name: res.data.name,
            phone: res.data.user.phone,
            gender: res.data.gender,
            birthday: res.data.birthday,
            remarks: res.data.remarks,
            address: res.data.address,
            region: res.data.region,
            label: res.data.label ? res.data.label.split(',') : []
          }
          state.memberPhone = res.data.user.phone
          state.detailShow = true
        } else {
          Toast(res.msg)
        }
      })
    }
    const onClick = (item) => {
      state.inviterId = item.inviteeId
      reset()
    }
    const onClear = () => {
      reset()
    }
    const onSearch = (value) => {
      reset()
    }
    const getData = () => {
      const data = {
        pageNum: state.page,
        staffId: state.staffId
      }
      if (state.searchKey) data.phone = state.searchKey
      post('/member.list', data).then(res => {
        if (res.code === 0) {
          state.items = [...state.items, ...res.data.content]
          state.loading = false
          state.finished = res.data.last
          state.page++
          state.title = '总共（' + res.data.totalElements + '人）'
        } else {
          Toast(res.msg)
        }
      })
    }
    const reset = () => {
      state.items = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const onSubmit = () => {
      if (!state.memberForm.name) return Toast('请输入会员姓名')
      if (!state.memberForm.birthday) return Toast('请选择会员生日')
      if (!state.memberForm.region) return Toast('请选择会员所在区域')
      if (!state.memberForm.address) return Toast('请输入会员详细地址')
      const data = JSON.parse(JSON.stringify(state.memberForm))
      if (data.label.length > 0) {
        data.label = data.label.join(',')
      } else {
        data.label = ''
      }
      state.btnLoading = true
      post('/member.update', data).then(res => {
        state.btnLoading = false
        if (res.code === 0) {
          state.detailShow = false
          state.items[state.memberIndex].name = data.name
          Toast('保存成功')
        } else {
          Toast(res.msg)
        }
      })
    }
    const onConfirm = (values) => {
      state.memberForm.region = values.map((item) => item.name).join('/')
      state.showArea = false
    }
    const onBirthConfirm = (values) => {
      const y = new Date(values).getFullYear()
      const m = new Date(values).getMonth() + 1
      const d = new Date(values).getDate()
      state.memberForm.birthday = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`
      state.showBirth = false
    }
    const tagConfirm = () => {
      if (state.tagText) {
        state.memberForm.label.push(state.tagText)
      }
    }
    const delTag = (index) => {
      state.memberForm.label.splice(index, 1)
    }
    const openTag = () => {
      state.tagText = ''
      state.showTag = true
    }
    const init = () => {
      // alert('13456789012'.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3'))
    }
    watch(() => useRoute.query, (to, previous) => {
    })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onSearch,
      onClear,
      onClick,
      getDetail,
      onSubmit,
      onConfirm,
      onBirthConfirm,
      tagConfirm,
      delTag,
      openTag,
      getMemberList,
      openRecord,
      changeTab
    }
  }
}
</script>

<style scoped>
.formbox{
  width: 90%;
  height: 80vh;
  margin:10vh auto;
  background: #FFFFFF;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 0;
  border-radius: 6px;
}

.infobox{
  border-bottom: 1px solid #f3f3f3;
  padding: 12px 0;
}
.infobox p{
  margin-bottom: 6px;
}
.infobox p:last-child{
  margin-bottom: 0;
}
</style>
